<!--
 * @Description: 商品列表
 * @Author: xiawenlong
 * @Date: 2022-01-04 08:54:22
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-01-19 10:27:27
-->
<template>
  <div class="product-manage">
    <table-list
      title="商品列表"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="dataList"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :options="{ selection: true, operatesWidth: 170 }"
      :pager="pager"
      :tabs-list="tabsList"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
//表格tabs
const tabsList = [
  {
    prop: 'status',
    activeValue: '',
    children: [
      { value: 0, label: '编辑中' },
      { value: 1, label: '审核中' },
      { value: 3, label: '待上架' },
      { value: 4, label: '已上架' },
    ],
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '创建商品',
    method: _this.handleAdd,
  },
  {
    label: '上架/下架',
    method: _this.handleUp,
  },
  {
    label: '导出',
    method: _this.handleExport,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '商品名称',
    prop: 'name',
  },
  {
    label: '商品类目',
    placeholder: '请选择商品类目',
    type: 'treeSelect',
    prop: 'productAttributeCategoryId',
    showLevels: false,
    options: _this.categoryTree,
    optionProps: {
      value: 'productAttributeCategoryId',
      label: 'name',
      children: 'children',
    },
  },
  // {
  //   label: '商品类目',
  //   type: 'select',
  //   prop: 'publishStatus',
  //   children: [
  //     { value: 1, label: '班级' },
  //     { value: 2, label: '课程' },
  //     { value: 3, label: '证书' },
  //   ],
  // },
  {
    label: '价格',
    type: 'section',
    propLeft: 'lowPrice',
    propRight: 'highPrice',
  },
  {
    label: '创建时间',
    type: 'picker',
    prop: 'createTime',
    pickerDate: _this.pickerDate,
  },
  {
    label: '上架时间',
    type: 'picker',
    prop: 'publishTime',
    pickerDate: _this.pickerDate,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'pic',
    label: '商品封面',
    minWidth: 112,
    render: (h, { row }) => {
      return [
        row.pic
          ? h('img', {
              domProps: { src: row.pic },
              style: 'width:92px;height:56px',
            })
          : null,
      ]
    },
  },
  {
    prop: 'name',
    minWidth: 160,
    label: '商品名称',
    twoLines: true,
  },
  {
    prop: 'resourceType',
    minWidth: 76,
    label: '商品类目',
    formatter: row => {
      return { 1: '班级', 2: '课程', 3: '证书' }[row.resourceType]
    },
  },
  {
    prop: 'price',
    minWidth: 185,
    label: '价格',
    align: 'right',
    sortdata: 5,
    render: (h, { row }) => {
      // render: h => {
      //   console.log(row)
      if (row.lowPrice && row.highPrice) {
        return h(
          'span',
          row.lowPrice == row.highPrice
            ? `￥${row.highPrice}`
            : `￥${row.lowPrice}—￥${row.highPrice}`,
        )
      } else if (row.lowPrice) {
        return h('span', row.lowPrice)
      } else if (row.highPrice) {
        return h('span', row.highPrice)
      } else {
        return h('span', `—`)
      }
    },
  },
  {
    prop: 'sale',
    minWidth: 72,
    label: '销量',
    align: 'right',
    sortdata: 1,
  },
  {
    prop: 'amount',
    minWidth: 123,
    label: '销售额',
    align: 'right',
    sortdata: 2,
    formatter: row => {
      return `￥${row.amount}`
    },
  },
  {
    prop: 'publishUserName',
    minWidth: 76,
    label: '发布人',
  },
  {
    prop: 'createTime',
    label: '创建时间',
    minWidth: 150,
    sortdata: 4,
  },
  {
    prop: 'publishTime',
    label: '上架时间',
    minWidth: 150,
    sortdata: 3,
  },
  {
    prop: 'status',
    label: '状态',
    minWidth: 62,
    render: (h, { row }) => {
      return h(
        'span',
        {
          style: {
            color: {
              0: '#F67878',
              1: '#FF9E68',
              2: '#333333',
              3: '#5D8BDB',
              4: '#333333',
              9: '#333333',
            }[row.status],
          },
        },
        { 0: '编辑中', 1: '审核中', 2: '审核失败', 3: '待上架', 4: '已上架', 9: '未知' }[
          row.status
        ],
      )
    },
    // formatter: row => {
    //   return { 0: '编辑中', 1: '审核中', 2: '审核失败', 3: '待上架', 4: '已上架', 9: '未知' }[
    //     row.status
    //   ]
    // },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '发布',
    method: _this.handlePublish,
    isShow: item => item.status == 0,
  },
  {
    label: '编辑',
    method: _this.handleEdit,
    isShow: item => item.status == 0 || item.status == 3,
  },
  {
    label: '销售详情',
    method: _this.handleDetail,
  },
]
import TableList from '@/components/TableList'
import {
  productCertPage,
  productCertCategoryTree,
  productCertExport,
  productCertPublish,
  productRemove,
  productCertSubmit,
} from '@/api/center'
import to from 'await-to'
export default {
  name: 'ProductManage',
  components: {
    TableList,
  },
  data() {
    return {
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
      btns,
      tabsList,
      searchForm,
      columns,
      operates,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      dataList: [],
      categoryTree: [],
      selectList: [],
    }
  },
  mounted() {
    this.getList()
    this.getCategoryTree()
  },
  methods: {
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(productCertPage({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.dataList = res.data.list
    },
    async getCategoryTree() {
      const [res, err] = await to(productCertCategoryTree({}))
      if (err) return this.$message.warning(err.msg)
      this.categoryTree = res.data
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.queryParams.productAttributeCategoryId =
        queryParams.productAttributeCategoryId[queryParams.productAttributeCategoryId.length - 1]
      if (queryParams.createTime && queryParams.createTime !== 0) {
        this.queryParams.createStartTime = queryParams.createTime[0]
        this.queryParams.createEndTime = queryParams.createTime[1].split(' ')[0] + ' 23:59:59'
      } else {
        this.queryParams.createStartTime = ''
        this.queryParams.createEndTime = ''
      }
      if (queryParams.publishTime && queryParams.publishTime !== 0) {
        this.queryParams.publishStartTime = queryParams.publishTime[0]
        this.queryParams.publishEndTime = queryParams.publishTime[1].split(' ')[0] + ' 23:59:59'
      } else {
        this.queryParams.publishStartTime = ''
        this.queryParams.publishEndTime = ''
      }
      this.getList()
    },
    handleAdd() {
      this.$router.push('/scenter/productManage/add')
    },
    handleEdit(row) {
      this.$router.push(`/scenter/productManage/${row.productId}`)
    },
    async handleUp() {
      if (!this.selectList.length) return this.$message.warning('请先选择商品')
      let productIdList = []
      this.selectList.map(v => productIdList.push(v.productId))
      const [, err] = await to(productCertPublish({ productIdList }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('操作成功')
      this.getList()
    },
    async handlePublish({ productId }) {
      const [, err] = await to(productCertSubmit({ productId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('发布成功')
      this.getList()
    },
    handleDelete() {
      if (!this.selectList.length) return this.$message.warning('请先选择要删除的商品')
      for (let i = 0; i < this.selectList.length; i++) {
        const element = this.selectList[i]
        if (element.status != 0 && element.status != 3)
          return this.$message.warning(
            '您所选的商品状态不符合删除要求，只有待上架和编辑中2个状态可以删除',
          )
      }
      let productIdList = []
      this.selectList.map(item => productIdList.push(item.productId))
      this.$confirm('确认删除商品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(productRemove({ productIdList }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getList()
      })
    },
    async handleExport() {
      let productIdList = []
      this.selectList.map(v => productIdList.push(v.productId))
      const { current, size } = this.pager
      const [res, err] = await to(
        productCertExport({ current, size, ...this.queryParams, productIdList }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '商品列表.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleDetail(row) {
      console.log(row)
      this.$router.push({ path: '/scenter/productDetail', query: { productId: row.productId } })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getList()
    },
  },
}
</script>
